<template>
  <div>
    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>
    <div class="row">
      <div class="flex xs12 sm4">
        <va-card :title="$t('trainings.trainings.view')">
          <actions
            v-if="!local && training.id != null"
            slot="actions"
            crud-links="trainings"
            controller="DcpiTrainings"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          >
            <template v-if="training.id != null">
              <va-popover
                :message="$t('tables.actions.export', {format: 'Excel'})"
                placement="down"
              >
                <va-button
                  flat
                  small
                  color="success"
                  icon="fa fa-file-excel-o"
                  @click.prevent="prepareDownload('xlsx')"
                />
              </va-popover>
            </template>
          </actions>
          <text-list
            v-if="hasNested(training, 'district.country.region')"
            :condition="currentUser.can('Regions', 'view') && !local"
            :title="$t('tables.headings.region')"
            :label="$t(training.district.country.region.name)"
            :to="{ name: 'regionsView', params: {id: training.district.country.region.id}}"
          />
          <text-list
            v-if="hasNested(training, 'district.country')"
            :condition="currentUser.can('Countries', 'view') && !local"
            :title="$t('tables.headings.country')"
            :label="$t(training.district.country.name)"
            :to="{ name: 'countriesView', params: {id: training.district.country.id}}"
          />
          <text-list
            v-if="training.district"
            :condition="currentUser.can('Districts', 'view') && !local"
            :title="$t('tables.headings.district')"
            :label="$t(training.district.name)"
            :to="{ name: 'districtsView', params: {id: training.district.id}}"
          />
          <text-list :title="$t('tables.headings.city')">
            {{ training.location }}
          </text-list>
          <text-list :title="$t('tables.headings.end_date')">
            {{ training.end_date | date }}
          </text-list>
          <text-list
            v-if="training.training_type"
            :condition="currentUser.can('TrainingTypes', 'view') && !local"
            :title="$t('tables.headings.training_type')"
            :label="training.training_type.name"
            :to="{ name: 'trainingTypesView', params: {id: training.training_type.id}}"
          />
          <text-list :title="$t('tables.headings.whatsapp')">
            {{ training.whatsapp }}
          </text-list>
          <text-list :title="$t('tables.headings.zoom')">
            {{ training.zoom }}
          </text-list>
          <text-list
            v-if="training.organizer"
            :condition="currentUser.can('Users', 'view') && !local"
            :title="$t('tables.headings.organizer')"
            :label="training.organizer.name"
            :to="{ name: 'usersView', params: {id: training.organizer.id}}"
          />
          <text-list
            v-if="training.organizer"
            :title="$t('tables.headings.email')"
          >
            {{ training.organizer.email }}
          </text-list>
          <text-list
            v-if="training.dcpi_role"
            :condition="currentUser.can('DcpiRoles', 'view') && !local"
            :title="$t('trainings.trainings.inputs.dcpiRoleInput')"
            :label="training.dcpi_role.name"
            :to="{ name: 'rolesView', params: {id: training.dcpi_role.id}}"
          />
          <text-list :title="$t('tables.headings.note')">
            {{ training.training_story }}
          </text-list>
          <text-list
            v-if="training.submitter"
            :condition="currentUser.can('Users', 'view') && !local"
            :title="$t('tables.headings.submitter')"
            :label="training.submitter.name"
            :to="{ name: 'usersView', params: {id: training.submitter.id}}"
          />
          <text-list
            v-if="training.submitter"
            :title="$t('tables.headings.email')"
          >
            {{ training.submitter.email }}
          </text-list>
          <text-list
            :title="$t('tables.headings.public_link')"
            v-if="!local && training.id != null"
          >
            <a
              :href="getPath('/academy/detail/' + training.id)"
              target="blank"
            >Link</a>
          </text-list>
        </va-card>
      </div>
      <div class="flex xs12 sm8">
        <va-card :title="$t('trainings.trainings.tabs.title')">
          <tabs-container :tabs="tabs">
            <template v-slot:trainers>
              <trainers-table
                :training="training"
                :loading="loading"
                :edit="false"
              />
            </template>
            <template v-slot:participants>
              <participants-table
                :training="training"
                :wizard="local"
                :loading="loading"
                :resend="currentUser.can('DcpiTrainings', 'sendCertificate')"
              />
            </template>
          </tabs-container>
        </va-card>
      </div>
    </div>

    <div
      class="row"
      v-if="training.photos && training.photos.length > 0"
    >
      <div class="flex xs12">
        <va-card :title="$t('trainings.trainings.tabs.photos.title')">
          <carousel>
            <slide
              v-for="image of training.photos"
              :key="image.id"
            >
              <img
                class="gallery-image"
                width="120px"
                v-lazy="getPath(image.path)"
                @click="showLightbox(image.path, training.id)"
              />
            </slide>
          </carousel>
          <light-box
            ref="lightbox"
            :show-caption="true"
            :show-light-box="false"
            :show-thumbs="false"
            :media="filteredImages"
          />
        </va-card>
      </div>
    </div>

    <div
      class="row"
      v-if="training.material && training.material.length > 0"
    >
      <div class="flex xs12">
        <va-card :title="$t('trainings.trainings.tabs.material.title')">
          <carousel>
            <slide>
              <local-table
                class="table-hover table-striped"
                :columns="documentsFields"
                :data="training.material"
                :loading="loading"
              >
              </local-table>
            </slide>
          </carousel>
          <light-box
            ref="lightbox"
            :show-caption="true"
            :show-light-box="false"
            :show-thumbs="false"
            :media="filteredImages"
          />
        </va-card>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Carousel, Slide } from 'vue-carousel'
import { hasOwnNestedProperty } from '@/services/utils'
const TrainersTable = () => import(/* webpackPrefetch: true */ './Trainers')
const LightBox = () => import(/* webpackPrefetch: true */ 'vue-image-lightbox')
const ParticipantsTable = () => import(/* webpackPrefetch: true */ './Participants')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')
const TabsContainer = () => import(/* webpackPrefetch: true */ '@/components/extras/TabsContainer')
const LocalTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/LocalTable')

export default {
  name: 'trainings-view',
  components: {
    ParticipantsTable,
    TrainersTable,
    Carousel,
    Slide,
    LightBox,
    TabsContainer,
    Actions,
    TextList,
    LocalTable,
  },
  props: {
    local: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data () {
    return {
      training: {},
      loading: false,
      error: false,
      images: [],
      documents: [],
      galleryFilter: null,
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    filteredImages: function () {
      if (this.galleryFilter === 'all') {
        return this.images
      } else {
        return this.images.filter(image => image.filter === this.galleryFilter)
      }
    },
    tabs () {
      return [
        { name: 'trainers', title: this.$t('trainings.trainings.tabs.trainers.title') },
        { name: 'participants', title: this.$t('trainings.trainings.tabs.participants.title') },
      ]
    },
    documentsFields () {
      return [
        {
          name: 'path_ma',
          title: this.$t('tables.headings.document'),
        },
      ]
    },
  },
  async created () {
    if (this.local) return

    await this.updateData()
    this.setImages()
    this.setMaterial()
  },
  methods: {
    hasNested (obj, property) {
      return hasOwnNestedProperty(obj, property)
    },
    routeBuilder (id) {
      return `trainings/dcpi/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const trainingId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(trainingId))
      } catch (err) {
        // console.log('Error fetching training data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.training = u.data.data
    },
    getPath (url) {
      if (this.local) return url

      let base = this.$http.defaults.baseURL.slice(0)
      if (base) {
        if (/\/api\//g.test(base)) {
          base = base.replace('/api/', '')
        }
        if (!/^https?:\/\/[^/]+/g.test(base)) {
          base = window.origin + base
        }
        url = base + url
      }

      return url
    },
    setImages () {
      if (!this.training || !this.training.photos) {
        return
      }

      const images = []
      for (const image of this.training.photos) {
        const i = {
          id: image.id,
          src: this.getPath(image.path),
          caption: '',
          filter: this.training.id.toString(),
        }

        images.push(i)
      }

      this.images = images.slice(0)
    },
    setMaterial () {
      if (!this.training || !this.training.material) {
        return
      }

      const documents = []
      for (const material of this.training.material) {
        const i = {
          id: material.id,
          src: this.getPath(material.path),
          caption: '',
          filter: this.training.id.toString(),
        }

        documents.push(i)
      }

      this.documents = documents.slice(0)
    },
    showLightbox: function (path, filter) {
      this.galleryFilter = filter.toString()
      path = this.getPath(path)

      const index = this.filteredImages.findIndex(i => i.src === path)
      this.$refs.lightbox.showImage(index)
    },
    async prepareDownload (format) {
      const prepareRoute = this.routeBuilder(this.training.id) + '?export=' + format
      const downloadRoute = 'trainings/download/'

      return this.downloadFile(prepareRoute, downloadRoute)
    },
    async downloadFile (prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.get(prepareRoute)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>

<style scoped>
.gallery-image {
  cursor: pointer;
}
</style>
